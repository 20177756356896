@import "~@/styles/spacing";
@import "~@/styles/colors";
@import "~@/styles/layout";
@import "~@/styles/utils";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

.banner {
  padding: rem-calc(64) 0;
  @include respond-to("small") {
    padding: rem-calc(80) 0;
  }
}

.label {
  color: $tints-obsidian-400;
  @include grid-container;
  @include separator-line;
  @include outer-spacing;
  grid-row: 1;
  grid-column: 1 / -1;
  .labelText {
    @include type_caption_title_small;
  }
}

.themeLight {
  color: $text-black;
}
