@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/breakpoints";

.marquee {
  overflow: hidden;
  width: 100%;
}

.container {
  margin-left: -10vw;
  margin-right: -10vw;
}

.line {
  display: inline-block;
  white-space: nowrap;
  animation-delay: 0.01s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.2, 0.01, 0.87, 1);
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);

  margin-bottom: spacer(32);
  animation-duration: 140s;
  @include respond-to("small") {
    animation-duration: 200s;
    margin-bottom: spacer(56);
  }

  &:nth-child(1) {
    animation-name: rotateLeft;
  }

  &:nth-child(2) {
    animation-name: rotateRight;
    flex-direction: row-reverse;
  }

  &:nth-child(3) {
    animation-name: rotateLeft2;
  }

  &:nth-child(4) {
    animation-name: rotateRight2;
    flex-direction: row-reverse;
  }

  &:nth-child(3),
  &:nth-child(4) {
    @include respond-to("small") {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    margin-left: rem-calc(20px);
    margin-right: rem-calc(20px);
    @include respond-to("small") {
      margin-left: rem-calc(40px);
      margin-right: rem-calc(40px);
    }
  }

  img {
    min-width: rem-calc(20px);
    min-height: rem-calc(20px);
    max-width: rem-calc(125px);
    max-height: rem-calc(50x);
    @include respond-to("small") {
      min-width: rem-calc(150px);
      min-height: rem-calc(20px);
      max-width: rem-calc(250px);
      max-height: rem-calc(100px);
    }
  }
}

@keyframes rotateRight {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%) translateX(-0.25em);
  }
}

@keyframes rotateRight2 {
  0% {
    -webkit-transform: translateX(-25%);
  }
  100% {
    -webkit-transform: translateX(-75%) translateX(-0.25em);
  }
}

@keyframes rotateLeft {
  0% {
    -webkit-transform: translateX(-50%) translateX(-0.25em);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes rotateLeft2 {
  0% {
    -webkit-transform: translateX(-75%) translateX(-0.25em);
  }
  100% {
    -webkit-transform: translateX(-25%);
  }
}
