@import "~@/styles/utils";
@import "~@/styles/colors";

.ctaArrowIcon {
  border: 1px solid currentColor;
  border-radius: rem-calc(68px);
  width: rem-calc(40px);
  height: rem-calc(40px);
  display: flex;
  align-items: center;
  justify-content: space-around;

  svg {
    width: rem-calc(19px);
    height: auto;
  }
}

.themeWhite {
  border-color: $background-white-elevation;
  background: $background-white-elevation;
  path {
    fill: $text-black;
  }
}
