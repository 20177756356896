@import "~@/styles/utils";
@import "~@/styles/spacing";

.arrow {
  width: rem-calc(16px);
  height: auto;

  path:first-of-type {
    fill: currentColor;
  }

  path:nth-of-type(2) {
    stroke: currentColor;
  }
}

.downloadIcon {
  border: 1px solid currentColor;
  border-radius: rem-calc(68px);
  width: rem-calc(40px);
  height: rem-calc(40px);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
