@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";

.content {
  @include grid-container;
  @include outer-spacing;
  @include grid;
  margin-top: spacer(64);
}

.heading {
  margin-bottom: spacer(24);
  grid-column: 1 / -1;
  @include respond-to("small") {
    margin-bottom: spacer(96);
    grid-row: 1;
    grid-column: 1 / 6;
  }

  h2 {
    @include type_heading_2_m;
    @include respond-to("small") {
      @include type_heading_1;
    }
  }
}

.lead {
  margin-bottom: spacer(64);
  grid-column: 1 / -1;
  @include respond-to("small") {
    grid-row: 2;
    grid-column: 7 / -1;
  }
}

.cta {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  @include respond-to("small") {
    flex-direction: row;
    grid-row: 3;
    grid-column: 7 / -1;
  }
}
