// BRAND
$brand-obsidian-black: #000000;
$brand-ultra-white: #ffffff;
$brand-kyber-green: #21c980;
$brand-oxford-brown: #472a31;

// TINTS
$tints-obsidian-800: #333333;
$tints-obsidian-600: #616161;
$tints-obsidian-400: #888888;
$tints-obsidian-200: #cacaca;
$tints-obsidian-100: #ededed;
$tints-obsidian-50: #f9f9f9;

$tints-kyber-800: #013830;
$tints-kyber-300: #8beab9;
$tints-kyber-100: #cff5dd;
$tints-kyber-50: #f4fbf6;

$tints-oxford-100: #cec0c0;
$tints-oxford-400: #917671;
$tints-oxford-600: #683e48;
$tints-oxford-800: #472A31;

$tints-kyber-200: #c2b1b1;
$tints-kyber-100: #cec0c0;
$tints-kyber-50: #efeaea;

// BACKGROUND
$background-black: #121212;
$background-primary-elevation: #1f1f1f; // #191919;
$background-white: #ffffff;
$background-white-elevation: #ededed;

// TEXT
$text-ultra-white: #ffffff;
$text-white: #e6e6e6;
$text-muted: #616161;
$text-black: #000000;
$text-cta: #21c980;
$text-cta-inverted: #1ca86b;

// STROKE
$stroke-ultra-white: #ffffff;
$stroke-white: #e6e6e6;
$stroke-muted: #616161;
$stroke-black: #000000;
$stroke-cta: #21c980;
$stroke-cta-inverted: #1ca86b;

// UI
$ui-positive: #4cdf93;
$ui-notice: #ffaf47;
$ui-negative: #e7583b;
$ui-informative: #0084ff;
$ui-success-text: #18874e;
$ui-error-text: #cf3819;
$ui-light-positive: #ecfbf1;
$ui-light-notice: #fff4e6;
$ui-light-negative: #fce9e5;
$ui-light-informative: #eef7ff;
