@import "~@/styles/utils";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/colors";

.subscribeButtonMobile,
.subscribeButton {
  background: none;
  cursor: pointer;

  span {
    @include type_body;
  }
}

.subscribeButtonMobile {
  appearance: none;
  transition: background-color cubic-bezier(0.25, 0, 0, 1) 320ms;
  will-change: background-color;
  border-radius: rem-calc(2px);
  background-color: $background-black;
  color: white;
  padding: rem-calc(20);
  width: 100%;

  &:disabled {
    background: $tints-obsidian-600;
  }
}

.subscribeButton {
  color: $text-black;
  display: flex;
  align-items: center;

  span {
    margin-right: spacer(16);
  }
}

.arrow {
  transition: background-color cubic-bezier(0.25, 0, 0, 1) 320ms;
  will-change: background-color;
  background-color: transparent;
}
