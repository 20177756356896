@import "~@/styles/colors";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

.container {
  @include grid-container;
  @include outer-spacing;
  @include grid;
}

.heading {
  grid-row: 1;
  grid-column: 1 / 6;
  margin-bottom: spacer(32);
  h2 {
    @include type_body;
    @include respond-to("small") {
      @include type_body_small;
    }
  }
}

.text {
  grid-row: 2;
  grid-column: 7 / -1;
  @include respond-to("small") {
    grid-row: 1;
  }
}

.themeLight {
  background-color: $background-white;
  color: $text-black;
}
