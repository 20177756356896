@import "~@/styles/spacing";
@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";

.portableText {
  padding-top: spacer(32);
  padding-bottom: spacer(64);
  @include respond-to("small") {
    padding-top: spacer(80);
    padding-bottom: spacer(120);
  }

  h1 {
    @include type_heading_3;
    margin-bottom: spacer(64);
    @include respond-to("small") {
      @include type_heading_1;
      margin-bottom: spacer(160);
    }
  }

  h2 {
    @include type_heading_2;
    margin-bottom: spacer(24);
    @include respond-to("small") {
      margin-bottom: spacer(48);
    }
  }

  h3 {
    @include type_heading_3;
    margin-bottom: spacer(16);
    @include respond-to("small") {
      margin-bottom: spacer(32);
    }
  }

  h4 {
    @include type_heading_4;
    margin-bottom: spacer(12);
    @include respond-to("small") {
      margin-bottom: spacer(24);
    }
  }

  p {
    @include type_body;
    margin-bottom: spacer(24);
    @include respond-to("small") {
      @include type_body_large;
      margin-bottom: spacer(48);
    }
  }

  blockquote {
    @include type_heading_3;
    margin-bottom: spacer(64);
    @include respond-to("small") {
      @include type_heading_1;
      margin-bottom: spacer(160);
    }
  }

  ul {
    margin-left: 1.5rem;
    list-style-type: disc;
    list-style-position: outside;
    li {
      @include type_body;
      margin-bottom: spacer(12);
      @include respond-to("small") {
        @include type_body_large;
      }
    }
  }
}

.light {
  background: $background-white;
  color: $text-black;
}
