@import 'fonts';
@import '~@/styles/utils';
@import '~@/styles/spacing';
@import '~@/styles/breakpoints';
@import 'fluid';

$font-family-1: 'Roobert', Helvetica, Verdana Arial, sans-serif;
$font-family-2: 'Untitled Sans', 'Times New Roman', Georgia Helvetica;

//
// Roobert Regular / Roobert Medium
//
@mixin type_display_1 {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.15;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(48px), rem-calc(72px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1024), rem-calc(72px), rem-calc(72px));
  }
  @include respond-to('tablet') {
    @include fluid-type(rem-calc(1024), rem-calc(1440), rem-calc(72px), rem-calc(92px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(160px), rem-calc(172px));
  }
}

@mixin type_display_2 {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.167;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(40px), rem-calc(52px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(52px), rem-calc(72px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(108px), rem-calc(128px));
  }
}

@mixin type_heading_1 {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.166666667;
  font-size: rem-calc(48px);
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(32px), rem-calc(40px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(40px), rem-calc(48px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(72px), rem-calc(88px));
  }
}

@mixin type_heading_2 {
  font-family: $font-family-1;
  font-weight: 400;
  line-height: 1.25;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(24px), rem-calc(28px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(28px), rem-calc(32px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(48px), rem-calc(56px));
  }
}

@mixin type_heading_2_m {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.25;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(24px), rem-calc(28px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(28px), rem-calc(32px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(48px), rem-calc(56px));
  }
}

@mixin type_heading_3 {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.25;
  font-size: rem-calc(24px);
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(22px), rem-calc(28px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(40px), rem-calc(48px));
  }
}

@mixin type_heading_4 {
  font-family: $font-family-1;
  font-weight: 500;
  line-height: 1.2;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(18px), rem-calc(20px));
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(32px), rem-calc(40px));
  }
}

@mixin type_nav_item {
  font-family: $font-family-1;
  font-weight: 500;
  font-size: rem-calc(18px);
  line-height: 1;
  text-decoration: none;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(18px), rem-calc(26px));
  }
}

@mixin type_number_tag {
  font-family: $font-family-1;
  font-weight: 500;
  font-size: rem-calc(14px);
  line-height: 1.4;
}

//
// Untitled Sans Regular / Untitled Sans Medium
//
@mixin type_display_3 {
  font-family: $font-family-2;
  font-weight: 400;
  line-height: 1.15;
  @include fluid-type(rem-calc(375), rem-calc(768), rem-calc(32px), rem-calc(48px));
  @include respond-to('small') {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(48px), rem-calc(52px));
  }
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(56px), rem-calc(64px));
  }
  /*

  // Note to self: Disabled fluid types because it's only used in news grid,
  // and that design has fixed heading height.
  @include respond-to("small") {
    @include fluid-type(rem-calc(768), rem-calc(1440), rem-calc(40px), rem-calc(64px));
  }
  */
}

@mixin type_body_xl {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(32px);
  line-height: 1.25;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(40px), rem-calc(48px));
  }
}

@mixin type_body_large {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(24px);
  line-height: 1.25;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(32px), rem-calc(40px));
  }
}

@mixin type_body {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(18px);
  line-height: 1.33;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(24px), rem-calc(32px));
  }
}

@mixin type_body_textlink {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(18px);
  line-height: 1.33;
}

@mixin type_body_small {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(16px);
  line-height: 1.375;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(24px), rem-calc(32px));
  }
}

@mixin type_button_cta {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(16px);
  line-height: 1.375;
}

@mixin type_caption_title {
  font-family: $font-family-2;
  font-weight: 500;
  font-size: rem-calc(12px);
  text-transform: uppercase;
}

@mixin type_caption {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(12px);
  text-transform: uppercase;
}

@mixin type_caption_title_small {
  font-family: $font-family-2;
  font-weight: 500;
  font-size: rem-calc(10px);
  text-transform: uppercase;
  line-height: 1.2;
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(10px), rem-calc(16px));
  }
}

@mixin type_caption_small {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(10px);
  text-transform: uppercase;
}

@mixin type_badge {
  font-family: $font-family-2;
  font-weight: 400;
  font-size: rem-calc(12px);
  @include respond-to('xxxlarge') {
    @include fluid-type(rem-calc(2560), rem-calc(3440), rem-calc(18px), rem-calc(24px));
  }
}
