@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/breakpoints";

.newsletter {
  background: $tints-obsidian-50;
  color: $text-black;
}

.container {
  padding: spacer(48) 0;
  @include respond-to("small") {
    padding: spacer(80) 0;
  }

  @include grid-container;
  @include outer-spacing;

  a:hover {
    color: $text-muted;
  }
}

.header {
  @include type_heading_3;
  @include respond-to("small") {
    @include type_heading_1;
  }
}

.inputRowBottom {
  display: flex;
  justify-content: flex-end;
}

.inputRow {
  position: relative;
  display: flex;

  margin-bottom: spacer(24);
  margin-top: spacer(40);
  padding-bottom: spacer(16);
  @include respond-to("small") {
    margin-bottom: spacer(32);
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $stroke-muted;
  }
  @include respond-to("xxxlarge") {
    margin-top: spacer(80);
  }

  &.error {
    border-color: $ui-negative;
  }
}

.formElement {
  input[type="text"] {
    background: none;
    box-shadow: none;
    appearance: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    width: 100%;
    @include type_body;
    &:focus {
      outline: none;
    }
    padding-bottom: spacer(16);
    border-bottom: 1px solid $stroke-muted;

    @include respond-to("small") {
      @include type_heading_2;
      border-bottom: none;
      padding: 0;
    }
  }
}

.name {
  flex-grow: 1;
  @include respond-to("small") {
    max-width: 50%;
  }
  label {
    display: none;
  }
}

.accept {
  margin-bottom: spacer(24);
  @include type_body_small;
  @include respond-to("small") {
    @include type_body;
  }
  @include respond-to("tablet") {
    margin: 0;
  }

  &.error {
    color: $ui-negative !important;
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include respond-to("small") {
      align-items: center;
      justify-content: flex-end;
    }

    cursor: pointer;

    span {
      transition: opacity cubic-bezier(0.25, 0, 0, 1) 640ms;
      will-change: opacity;
    }
  }

  input[type="checkbox"] {
    color: currentColor;
    cursor: pointer;

    margin: 0;
    padding: 0;

    vertical-align: middle;
    -webkit-appearance: none;

    width: rem-calc(12);
    height: rem-calc(12);
    border: none;
    transition: all cubic-bezier(0.25, 0, 0, 1) 320ms;
    will-change: all;
    border-radius: 50%;
    background-color: transparent;
    &:checked {
      background-color: currentColor;
    }
  }
}

.submit {
  margin-top: spacer(40);
  @include respond-to("small") {
    margin: 0;
  }
}

.submissionFeedback {
  text-align: center;
  margin-top: spacer(24);
}

.borderline {
  position: relative;
  border: 1px solid currentColor;
  border-radius: 50%;
  width: rem-calc(16px);
  height: rem-calc(16px);
  vertical-align: middle;
  -webkit-appearance: none;
  margin-right: spacer(12);
  margin-top: rem-calc(4);
  @include respond-to("small") {
    margin-top: 0;
  }

  .borderlineWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.errorMessage {
  position: absolute;
  left: 0;
  bottom: rem-calc(-10);
  @include respond-to("small") {
    bottom: rem-calc(-30);
  }

  display: flex;
  align-items: center;

  svg {
    margin-right: spacer(8);
  }

  span {
    color: $ui-negative;
    @include type_caption_title;
  }
}

.arrow {
  background-color: transparent;
  path {
    fill: currentColor;
  }
}

.subscribeArrowIsValid {
  background-color: $text-black;
  path {
    fill: $text-white;
  }
}
