@import '~@/styles/utils';
@import '~@/styles/layout';
@import '~@/styles/typography';
@import '~@/styles/spacing';
@import '~@/styles/breakpoints';

.pageHeader {
  padding: spacer(40) 0 spacer(120) 0;

  &.scrollButtonSpacing {
    padding-bottom: spacer(48);
  }

  @include respond-to('small') {
    padding: spacer(120) 0 spacer(100) 0;
    min-height: 30vh;
  }
}

.container {
  @include grid-container;
  @include outer-spacing;
  @include grid;
}

.header {
  padding-bottom: spacer(80);
  grid-column: 1 / -1;

  @include respond-to('tablet') {
    grid-column: 1 / 6;
  }

  // På 1024 så brekker vi høyresiden under.
  h1 {
    @include type_display_1;

    &:before {
      content: '';
      display: table;
      margin-bottom: -0.32em;
    }

    &:after {
      content: '';
      display: table;
      margin-top: -0.18em;
    }
  }
}

.subheader {
  margin-bottom: spacer(24);
  @include type_heading_2;

  @include respond-to('tablet') {
    margin-bottom: spacer(32);
  }

  &:before {
    content: '';
    display: table;
    margin-bottom: -0.31em;
  }

  &:after {
    content: '';
    display: table;
    margin-top: -0.22em;
  }
}

.text {
  @include type_body;
  grid-column: 1 / -1;
  max-width: none;

  @include respond-to('small') {
    margin-bottom: spacer(48);
    max-width: 80%;
  }

  @include respond-to('tablet') {
    grid-column: 7 / -1;
    max-width: none;
  }

  @include respond-to('xlarge') {
    grid-column: 7 / span 5;
  }

  @include respond-to('xxxlarge') {
    grid-column: 7 / span 6;
  }
}

.explore {
  display: flex;
  justify-content: space-around;
  margin-top: spacer(80);

  @include respond-to('small') {
    display: block;
    margin-top: spacer(48);
  }
}

//
// Block content
//
.body,
.bodySmall,
.bodyLarge {
  margin-bottom: spacer(24);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.bodySmall {
  @include type_body_small;
}

.bodyLarge {
  @include type_body_large;
}