@import "~@/styles/utils";
@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/breakpoints";

.button {
  @include type_body;

  transition: all 0.32s cubic-bezier(0.25, 0, 0, 1);
  will-change: background-color color;
  background-color: $background-black;
  color: $text-white;
  &:hover {
    background-color: $background-white;
    color: $text-black;
  }

  border-color: $text-white;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;

  width: 100%;
  @include respond-to("small") {
    width: rem-calc(327);
  }
  @include respond-to("xxlarge") {
    width: rem-calc(400);
  }

  /**
  * Makes the child element fill the container,
  * and remove default styles.
  */
  display: inline-block;

  * {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: transparent;
    color: currentColor;
    cursor: pointer;
    text-decoration: none;
    text-align: center;

    padding: rem-calc(20px) rem-calc(24px);
    @include respond-to("small") {
      padding: rem-calc(20px) rem-calc(24px);
    }
  }
}

//
// THEMES
//
.kyber {
  color: $brand-kyber-green;
  border-color: $brand-kyber-green;
  &:hover {
    background: $brand-kyber-green;
  }
}

.inverse {
  background: $background-white;
  color: $text-black;
  border-color: $text-black;
  &:hover {
    background: $text-black;
    color: $background-white-elevation;
  }
}

.inactive,
.inactive:hover {
  background: $text-muted;
  color: $text-muted;
}
