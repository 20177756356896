@import "~@/styles/spacing";
@import "~@/styles/breakpoints";

@mixin grid-container {
  max-width: rem-calc(2178);
  margin: auto;
}

@mixin outer-spacing {
  padding-left: spacer(24);
  padding-right: spacer(24);
  @include respond-to("small") {
    padding-left: spacer(48);
    padding-right: spacer(48);
  }
}

@mixin grid {
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: spacer(24);
  grid-template-columns: repeat(4, 1fr);
  @include respond-to("small") {
    grid-template-columns: repeat(12, 1fr);
  }
}
