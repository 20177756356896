@font-face {
  font-family: 'Roobert';
  src: url('/fonts/Roobert/Roobert-Regular.woff2') format('woff2'),
    url('/fonts/Roobert/Roobert-Regular.woff') format('woff'),
    url('/fonts/Roobert/Roobert-Regular.eot') format('eot');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert';
  src: url('/fonts/Roobert/Roobert-Medium.woff2') format('woff2'),
    url('/fonts/Roobert/Roobert-Medium.woff') format('woff'),
    url('/fonts/Roobert/Roobert-Medium.eot') format('eot');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/Untitled Sans/untitled-sans-web-regular.woff2') format('woff2'),
    url('/fonts/Untitled Sans/untitled-sans-web-regular.woff') format('woff'),
    url('/fonts/Untitled Sans/untitled-sans-web-regular.eot') format('eot');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Untitled Sans';
  src: url('/fonts/Untitled Sans/untitled-sans-web-medium.woff2') format('woff2'),
    url('/fonts/Untitled Sans/untitled-sans-web-medium.woff') format('woff'),
    url('/fonts/Untitled Sans/untitled-sans-web-medium.eot') format('eot');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
