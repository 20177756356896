@import '~@/styles/layout';
@import '~@/styles/spacing';
@import '~@/styles/colors';
@import '~@/styles/typography';
@import '~@/styles/breakpoints';

.container {
  @include grid-container;
  @include outer-spacing;
  padding-top: spacer(80);
  padding-bottom: spacer(72); //spacer(80);
  @include respond-to('small') {
    padding-top: spacer(160);
    padding-bottom: spacer(160);
  }
}

.header {
  text-align: center;
  margin-bottom: spacer(16);
  @include type_heading_1;
}

.text {
  text-align: center;
  @include type_body_small;
  margin-bottom: spacer(64);
  margin-left: auto;
  margin-right: auto;
  @include respond-to('small') {
    max-width: rem-calc(470);
    margin-bottom: spacer(72);
  }
  @include respond-to('xxxlarge') {
    max-width: rem-calc(780);
  }
}

.gridContainer {
  @include grid;
}

.formElement {
  grid-column: auto / span 6;
  display: flex;
  flex-direction: column;


  label {
    text-align: left;
    padding-top: spacer(8);
    border-top: 1px solid $tints-obsidian-600;
    display: block;
    @include type_caption_title_small;
    color: $text-muted;
    transition: all cubic-bezier(0.25, 0, 0, 1) 320ms;
    will-change: all;
  }

  input {
    width: 100%;
    appearance: none;
    background: none;
    border: none;
    color: $text-white;
    padding: 0;

    @include type_heading_2_m;
    margin-top: spacer(24);
    margin-bottom: spacer(16);
    @include respond-to('small') {
      margin-top: spacer(40);
    }


    &::placeholder {
      color: $text-muted;
    }

    &:focus {
      outline: none;
      & + label {
        border-color: $stroke-white;
        color: $text-white;
      }
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 60px $background-black inset !important;
      -webkit-text-fill-color: $text-white !important;
    }

    &:-webkit-autofill::first-line {
      @include type_heading_2_m;
    }
  }

  &.error {
    input + label,
    input:focus + label {
      border-color: $ui-negative;
      color: $ui-negative;
    }
  }
}

.formElementLarge {
  @extend .formElement;
  @include respond-to('small') {
    grid-column: 1 / -1;
  }
}

.form {
  text-align: center;
}

.privacy {
  grid-column: 1 / span 12;

  display: flex;
  justify-content: space-around;

  margin-top: spacer(16);
  color: $text-muted;
  @include respond-to('small') {
    margin-top: spacer(48);
  }

  p {
    transition: color cubic-bezier(0.25, 0, 0, 1) 320ms;
    will-change: color;
    text-align: left;
    @include respond-to('small') {
      text-align: unset;
    }
  }

  &.error {
    color: $ui-negative;
  }

  &.valid {
    color: $text-white;
  }

  input {
    margin-right: spacer(12);
    height: 100%;
  }

  p {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: flex;
    @include respond-to('small') {
      align-items: center;
    }
  }

  input[type='checkbox'] {
    color: currentColor;
    cursor: pointer;

    margin: 0;
    padding: 0;

    vertical-align: middle;
    -webkit-appearance: none;

    width: rem-calc(12);
    height: rem-calc(12);

    transition: all cubic-bezier(0.25, 0, 0, 1) 320ms;
    will-change: all;
    border-radius: 50%;
    background-color: transparent;
    &:checked {
      background-color: currentColor;
    }
  }

  .borderline {
    position: relative;
    border: 1px solid currentColor;
    border-radius: 50%;
    width: rem-calc(16px);
    height: rem-calc(16px);
    vertical-align: middle;
    -webkit-appearance: none;
    margin-right: spacer(12);
    margin-top: rem-calc(4);
    @include respond-to('small') {
      margin-top: 0;
    }

    .borderlineWrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}

.submitButton {
  cursor: pointer;
  appearance: none;
  background: none;
  width: rem-calc(128);
  height: rem-calc(128);
  border: 1px solid $stroke-white;
  border-radius: 50%;
  color: $text-white;
  margin: spacer(80) auto 0 auto;

  @include respond-to('xxxlarge') {
    width: rem-calc(190);
    height: rem-calc(190);
    font-size: rem-calc(28px);
  }

  transition: all;
  transition-duration: 320ms;
  transition-timing-function: cubic-bezier(0.25, 0, 0, 1);

  &.validButton {
    color: $text-black;
    background: $background-white-elevation;
  }

  &:hover {
    color: $text-black;
    background: $tints-obsidian-50;
  }
}

.submissionFeedback {
  text-align: center;
  margin-top: spacer(40);
}
