@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/colors";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

.banner {
  margin-bottom: 0;
  padding-bottom: 0;
}

.content {
  margin-top: spacer(64);
}

.intro {
  @include grid-container;
  @include outer-spacing;
  @include grid;

  margin-bottom:rem-calc(96px);
  @include respond-to("small") {
    margin-bottom: spacer(160);
  }

  p:first-of-type {
    @include type_body_large;
    margin-bottom: spacer(48);
    grid-column: 1 / -1;
    @include respond-to("small") {
      grid-column: span 6;
    }
  }

  p:nth-of-type(2) {
    @include type_body;
    grid-column: 1 / -1;
    @include respond-to("small") {
      grid-column: 8 / 13;
    }
  }
}

.caseList {
  margin: 0;
}
