.root {
  position: relative;
  color: red;
  background-color: lightsalmon;
  font-weight: 600;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid lightcoral;

  &:after {
    content: 'not found';
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
  }
}
