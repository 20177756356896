@import "~@/styles/breakpoints";
@import "~@/styles/spacing";
@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";

.emailList {
  margin-bottom: spacer(32);

  ul {
    @include outer-spacing;
    @include grid;
  }

  li {
    grid-column: 1 / -1;
    margin-bottom: spacer(40);
    @include respond-to("small") {
      grid-column: 7 / -1;
    }
    @include respond-to("large") {
      margin-bottom: 0;
      grid-column: span 2;
    }
  }
  li:first-of-type {
    @include respond-to("small") {
      grid-column: 7 / span -1;
    }
    @include respond-to("large") {
      grid-column: 7 / span 2;
    }
  }
}

.tLabel {
  @include type_caption_title;
  color: $text-muted;
  margin-bottom: spacer(8);
  @include respond-to("small") {
    margin-bottom: spacer(12);
  }
}

.tEmailAddress {
  @include type_body_small;
}
