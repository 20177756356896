@import "~@/styles/utils";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";

.twoColumnTextBanner {
  @include grid-container;
  @include outer-spacing;
  padding-top: rem-calc(64);
  padding-bottom: rem-calc(24);
  @include respond-to("small") {
    padding-top: rem-calc(50);
    padding-bottom: 0;
  }

  .rightColumn:last-of-type {
    margin-bottom: 0;
  }
}

.content {
  @include respond-to("small") {
    @include grid;
    row-gap: rem-calc(96);
  }
}

.leftColumn {
  grid-column: 1 / -1;
  margin-bottom: rem-calc(48);
  @include respond-to("small") {
    grid-column: span 4;
    margin-bottom: 0;
  }

  p {
    @include type_body_large;
    @include respond-to("small") {
      @include type_body;
    }
  }
}

.rightColumn {
  margin-bottom: rem-calc(88);
  grid-column: 1 / -1;
  @include respond-to("small") {
    grid-column: 7 / -1;
    margin-bottom: 0;
  }

  p {
    @include type_body_small;
    @include respond-to("small") {
      @include type_body_large;
    }
  }
}

.theme {
  background-color: white;
  color: black;
}

.noPaddingTop {
  padding-top: 0;
}
