@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/colors";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

@mixin fill-absolute {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.case {
  @include grid-container;
  @include respond-to("xxxlarge") {
    @include outer-spacing;
  }

  overflow: hidden;
}

.case:hover {
  .tDescription,
  .tLink,
  .datapoints {
    opacity: 1;
  }
  .image::after {
    opacity: 0.65;
  }
}

.tDescription,
.tLink,
.datapoints {
  @include respond-to("small") {
    opacity: 0;
    transition: opacity cubic-bezier(0.75, 0, 0, 1) 175ms;
    will-change: opacity;
  }
}

.image {
  position: relative;
  height: 100%;
  width: 100%;

  img {
    z-index: 0;
  }

  &::after {
    @include fill-absolute;
    z-index: 1;
    background: #000;

    opacity: 0.65;
    @include respond-to("small") {
      transition: opacity cubic-bezier(0.75, 0, 0, 1) 175ms;
      will-change: opacity;
      opacity: 0.3;
    }
  }
}

.container {
  // @include grid-container;
  position: relative;
  height: rem-calc(375px);
  @include respond-to("small") {
    height: rem-calc(520px);
  }
}

.overlay {
  @include fill-absolute;
  z-index: 2;
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include outer-spacing;
  padding-top: spacer(32);
  padding-bottom: spacer(32);
  @include respond-to("small") {
    padding-top: spacer(48);
    padding-bottom: spacer(48);
  }
}

.content {
  @include grid;

  .left {
    grid-column: 1 / -1;
    @include respond-to("small") {
      grid-column: 1 / 6;
    }
    @include respond-to("medium") {
      grid-column: 1 / 6;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    grid-column: 7 / -1;
    @include respond-to("medium") {
      grid-column: 7 / -1;
    }
    @include respond-to("large") {
      grid-column: 9 / -1;
    }
  }
}

.datapoints {
  opacity: 0;
  margin-top: spacer(24);

  display: none;
  @include respond-to("small") {
    display: flex;
  }

  div {
    max-width: rem-calc(125px);
    margin-right: spacer(48);
    display: none;
  }

  @include respond-to("small") {
    div:nth-of-type(1),
    div:nth-of-type(2) {
      display: block;
    }
  }
  @include respond-to("medium") {
    div:nth-of-type(3) {
      display: block;
    }
  }
  @include respond-to("large") {
    div:nth-of-type(4) {
      display: block;
    }
  }

  dt {
    @include type_heading_2;
  }

  dd {
    @include type_caption_title;
  }
}

//
// Typography
//
.tTitle {
  @include type_heading_2_m;
  margin-bottom: spacer(16);
  @include respond-to("small") {
    @include type_heading_1;
    margin-bottom: spacer(24);
  }
}

.tDeveloper {
  @include type_caption_title;
}

.tDescription {
  display: none;
  @include respond-to(small) {
    display: -webkit-box;
  }

  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @include type_body_large;
}

.tLink {
  @include type_body;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-end;

  span {
    display: block;
  }

  svg {
    margin-left: spacer(16);
    margin-right: spacer(8);
    width: rem-calc(40px);
    height: auto;
  }
}
