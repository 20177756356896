@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";

.content {
  @include grid-container;
  @include outer-spacing;
  @include grid;

  margin-top: spacer(64);
  @include respond-to("small") {
    margin-top: spacer(48);
  }
}

.left {
  margin-bottom: spacer(40);
  grid-column: 1 / -1;
  @include respond-to("small") {
    margin-bottom: spacer(48);
    grid-row: 1;
    grid-column: 1 / 7;
  }

  h2 {
    @include type_heading_2_m;
    @include respond-to("small") {
      @include type_heading_1;
    }
  }
}

.right {
  grid-column: 1 / -1;
  margin-bottom: rem-calc(64px);
  @include respond-to("small") {
    margin-bottom: rem-calc(112px);
    grid-row: 2;
    grid-column: 7 / -1;
  }
}

.links {
  display: flex;
  flex-direction: column;
  .button {
    margin-bottom: spacer(16);
  }
  .button:last-of-type {
    margin-bottom: 0;
  }
}

.tLead {
  margin-bottom: spacer(64);
  @include respond-to("small") {
    margin-bottom: spacer(48);
  }
}
