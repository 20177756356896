@import "~@/styles/utils";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/colors";

.downloadLink {
  display: flex;
  align-items: center;
  padding: spacer(12) spacer(32) spacer(12) spacer(32);
  border-radius: rem-calc(2px);
  @include type_body;
  text-decoration: none;
  color: $text-black;
  background: $background-white-elevation;
}

.downloadLink:hover {
  background: $background-white;
}

.spacer {
  margin-right: spacer(12);
}
