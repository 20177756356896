@import "rem";

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

@mixin separator-line {
  &:before {
    height: 1px;
    display: block;
    background: currentColor;
    content: "";
    margin-bottom: rem-calc(8px);
  }
}

@mixin animated-underline {
  &::after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: rem-calc(-5px);
    left: 0;
    right: 0;
    background: currentColor;
    opacity: 0;
    transform: scaleX(0);
    transition: transform cubic-bezier(0.25, 0, 0, 1) 0.32s;
    will-change: transform;
  }

  &:hover::after {
    opacity: 1;
    transform: scaleX(1);
  }
}
